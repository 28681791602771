import "./Servico.style.scss";
import React from "react";
import {Card, Col, Container, Row} from "../../components";
import {Icon} from "@govbr-ds/react-components";
import mapa from "../../images/mapa.svg";
import previdencia from "../../images/previdencia.svg";

interface Mensagem {
    type: any;
    mensagem: string;
}

export default function Servico() {
    return (
        <Container className={'container-servicos'}>
            <Row>
                <Col sm={12} md={12} lg={12} className={'text-center'}>
                    <h2>Serviços</h2>
                    <p>Este é o acesso para os serviços, você pode acessar recursos disponíveis.</p>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'http://transparencia.vilhena.ro.gov.br/'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-search'} size={'3x'}/></div>
                            <h4>Portal Transparência</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5'>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-info'} size={'3x'}/></div>
                            <h4>e-SIC</h4>
                            <div className="menu">
                                <ul>
                                    <li className={'title label'}>Acessar Serviços</li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="http://transparencia.vilhena.ro.gov.br/portaltransparencia/esic"
                                           title="e-SIC"> e-SIC </a></li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="http://transparencia.vilhena.ro.gov.br/portaltransparencia/sic-consulta"
                                           title="Consultar e-SIC"> Consultar e-SIC </a></li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="http://transparencia.vilhena.ro.gov.br/portaltransparencia/sic-fisico"
                                           title="SIC-Físico"> SIC-Físico </a></li>
                                </ul>
                            </div>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://vilhenaro.webiss.com.br/'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-credit-card'} size={'3x'}/></div>
                            <h4> WebISS - Nota Fiscal de Serviço Eletrônica</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col>
                    <Card mt='5' href={'https://vilhena.oxy.elotech.com.br/portal-servidor/'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-users'} size={'3x'}/></div>
                            <h4>Portal RH</h4>
                        </Card.Content>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://apps.vilhena.ro.gov.br/cemiterio'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'far fa-file'} size={'3x'}/></div>
                            <h4>Web Cemitério</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5'>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-dollar-sign'} size={'3x'}/></div>
                            <h4>Portal do Contribuinte</h4>
                            <div className="menu">
                                <ul>
                                    <li className={'title label'}><a id="module-item-a" target="_blank" style={{color: '#ffffff'}}
                                                                     href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/inicio "
                                                                     title="Acessar Serviços">Acessar
                                        Serviços</a></li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/consulta-carne"
                                           title="Emissão de 2ª Via de Carnê"> Emissão de 2ª Via de Carnê </a>
                                    </li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/consulta-debitos"
                                           title="Consulta de Débitos"> Consulta de Débitos </a></li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/emissao-certidoes"
                                           title="Emissão de Certidões"> Emissão de Certidões </a></li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="https://vilhena.oxy.elotech.com.br/alvaraonline"
                                           title="Alvará"> Alvará </a>
                                    </li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="https://vilhena.oxy.elotech.com.br/cidadao"
                                           title="ITBI on-line - Cidadão"> ITBI on-line - Cidadão </a></li>
                                    <li><a id="module-item-a" target="_blank"
                                           href="https://vilhena.oxy.elotech.com.br/itbi"
                                           title="ITBI on-line - Servidor Municipal"> ITBI on-line - Servidor
                                        Municipal </a></li>
                                </ul>
                            </div>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://www1.consignet.com.br/portal/#!/vilhena'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-pager'} size={'3x'}/></div>
                            <h4>Portal Consignet</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col>
                    <Card mt='5' href={'https://vilhena.oxy.elotech.com.br/protocolo/1/consultaProcesso'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'far fa-file-alt'} size={'3x'}/></div>
                            <span className={'text-danger text-center'}>Novo sistema</span>
                            <h4 className={'mt-2'}>Controle de Processos OXY Protocolo</h4>
                        </Card.Content>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://transparencia.vilhena.ro.gov.br/portaltransparencia/licitacoes'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-hammer'} size={'3x'}/></div>
                            <h4>Licitações</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://vilhena.omegaeducacional.com'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-graduation-cap'} size={'3x'}/></div>
                            <h4>Sistema Educacional</h4>

                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://dov.vilhena.ro.gov.br/'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-book'} size={'3x'}/></div>
                            <h4>DOV - Diário Oficial</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col>
                    <Card mt='5' href={'https://webmail-seguro.com.br/'}>
                        <Card.Content>
                            <div className={'circle-icon'}><Icon icon={'fas fa-at'} size={'3x'}/></div>
                            <h4>E-mails</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://www.google.com/maps/d/u/0/viewer?mid=1KHtP9XBolF3Xa0GE5hG4Ha3m8_w_DnU&ll=-12.753912235768915%2C-60.13358220898438&z=13'}>
                        <Card.Content>
                            <div className={'circle-icon'}><img src={mapa} alt=""/></div>
                            <h4>Mapa</h4>
                        </Card.Content>
                    </Card>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Card mt='5' href={'https://vivaprev.com.br/'}>
                        <Card.Content>
                            <div className={'circle-icon'}><img src={previdencia} className={'ml-1'} alt=""/></div>
                            <h4>Previdencia</h4>
                        </Card.Content>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}